body,
nav,
header,
section,
footer,
.container {
  position: relative;
}

.section {
	padding: 2rem 0;
	&.section-white {
		background: #fff;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-gray {
    background: #f5f5f5;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-black {
		background: #000;
		color: #fff;
		hr, .page-header {
			border-color: rgba(255,255,255,0.25);
		}
	}
	&.section-lg {
  	padding: 4rem 0;
		min-height: 100vh;
	}
}

.center-vert {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.widget {
  margin-bottom: 2rem;
}

hr {
  margin: 2rem 0;
  border: 0px none;
}

.lead {
	strong {
		font-weight: 700;
	}
}